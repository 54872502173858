import * as tailwindConfig from './lib/tailwind/tailwind.config';
import * as Utils from './utils';
export const semantic = tailwindConfig.theme.extend.semantic;
export const semanticColors = tailwindConfig.theme.extend.semantic.color;
export const coreColors = tailwindConfig.theme.extend.core.color;
export const fontSizes = Utils.mapObjectValuesToArrayValues(Utils.flattenObject({
  nj: {
    ...semantic.size.font
  }
}));
export const fontFamilies = Utils.mapObjectValuesToArrayValues(Utils.flattenObject({
  nj: {
    ...semantic.font.family
  }
}));
export const opacities = Utils.flattenObject({
  nj: {
    ...semantic.opacity
  }
});
export const fontWeight = Utils.mapObjectValuesToArrayValues(Utils.flattenObject({
  nj: {
    ...semantic.font.weight
  }
}));

/** @type {import('tailwindcss').Config} */
export const FluidTailwindPresets = {
  colors: {
    transparent: 'transparent',
    current: 'currentColor',
    ...coreColors
  },
  theme: {
    extend: {
      colors: {
        nj: {
          // We set icon here because tailwind doesn't have a way
          // to specify icon colors
          icon: {
            ...semantic.color.icon
          }
        }
      },
      backgroundColor: {
        nj: {
          ...semantic.color.background
        }
      },
      textColor: {
        nj: {
          ...semantic.color.text
        }
      },
      borderColor: {
        nj: {
          ...semantic.color.border
        }
      },
      divideColor: {
        nj: {
          ...semantic.color.border
        }
      },
      spacing: {
        ...semantic.size.spacing
      },
      fontSize: fontSizes,
      fontFamily: fontFamilies,
      fontWeight: fontWeight,
      opacity: opacities
    }
  }
};