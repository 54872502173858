export const Tokens = {
  "semantic": {
    "color": {
      "background": {
        "neutral": {
          "primary": {
            "default": {
              "value": "#ffffff",
              "comment": "Sets the background color to white, provides a clean and minimalist canvas for the interface. Classic foundation for content and components."
            },
            "hovered": {
              "value": "#ebeef1"
            },
            "focused": {
              "value": "#ebeef1"
            },
            "pressed": {
              "value": "#ccd4da"
            },
            "selected": {
              "value": "#e7eefc"
            },
            "translucent": {
              "default": {
                "value": "rgba(0, 0, 0, 0)"
              },
              "hovered": {
                "value": "rgba(5, 43, 80, 0.08)"
              },
              "focused": {
                "value": "rgba(5, 43, 80, 0.08)"
              },
              "pressed": {
                "value": "rgba(0, 40, 70, 0.2)"
              },
              "selected": {
                "value": "rgba(15, 85, 225, 0.1)"
              }
            }
          },
          "secondary": {
            "default": {
              "value": "#f6f8f9",
              "comment": "Provides a subtle contrast with the primary background. Use to add a touch of visual interest without overpowering the content."
            },
            "hovered": {
              "value": "#ebeef1"
            },
            "focused": {
              "value": "#ebeef1"
            },
            "pressed": {
              "value": "#ccd4da"
            },
            "translucent": {
              "default": {
                "value": "rgba(30, 80, 105, 0.04)"
              },
              "hovered": {
                "value": "rgba(5, 43, 80, 0.08)"
              },
              "focused": {
                "value": "rgba(5, 43, 80, 0.08)"
              },
              "pressed": {
                "value": "rgba(0, 40, 70, 0.2)"
              }
            }
          },
          "tertiary": {
            "default": {
              "value": "#ebeef1",
              "comment": "Provides a slightly stronger contrast with the primary background. Use to enhances the visual separation between different sections or elements."
            },
            "hovered": {
              "value": "#ccd4da"
            },
            "focused": {
              "value": "#ccd4da"
            },
            "pressed": {
              "value": "#9eadb8"
            },
            "translucent": {
              "default": {
                "value": "rgba(5, 43, 80, 0.08)"
              },
              "hovered": {
                "value": "rgba(0, 40, 70, 0.2)"
              },
              "focused": {
                "value": "rgba(0, 40, 70, 0.2)"
              },
              "pressed": {
                "value": "rgba(6, 45, 73, 0.39)"
              }
            }
          },
          "solid": {
            "default": {
              "value": "#60798b",
              "comment": "Provides a distinct and noticeable contrast with the primary background. Adds a robust and substantial presence, use it to make the element stand out."
            },
            "hovered": {
              "value": "#435c6e"
            },
            "focused": {
              "value": "#435c6e"
            },
            "pressed": {
              "value": "#334551"
            }
          },
          "inverse": {
            "default": {
              "value": "#ffffff",
              "comment": "Applies white as the background color, creating a striking visual effect when used on a dark-colored surface. Use to ensure readability and visual appeal in specific UI components or sections."
            },
            "hovered": {
              "value": "#ebeef1"
            },
            "focused": {
              "value": "#ebeef1"
            },
            "pressed": {
              "value": "#ccd4da"
            },
            "translucent": {
              "default": {
                "value": "rgba(0, 0, 0, 0)"
              },
              "hovered": {
                "value": "rgba(0, 40, 70, 0.2)"
              },
              "focused": {
                "value": "rgba(0, 40, 70, 0.2)"
              },
              "pressed": {
                "value": "rgba(6, 45, 73, 0.39)"
              }
            }
          },
          "spotlight": {
            "default": {
              "value": "#232d35",
              "comment": "Creates a high contrast with the primary background. Use it for critical elements or components that require maximum emphasis and visual impact."
            }
          }
        },
        "brand": {
          "primary": {
            "translucent": {
              "default": {
                "value": "rgba(0, 0, 0, 0)"
              },
              "hovered": {
                "value": "rgba(15, 85, 225, 0.1)"
              },
              "focused": {
                "value": "rgba(15, 85, 225, 0.1)"
              },
              "pressed": {
                "value": "rgba(3, 83, 231, 0.25)"
              }
            }
          },
          "secondary": {
            "default": {
              "value": "#f6f8fd",
              "comment": "Establishes a subtle contrast with the primary background while using the brand color. Use it to add a touch of brand interest without overpowering the content."
            },
            "hovered": {
              "value": "#e7eefc"
            },
            "focused": {
              "value": "#e7eefc"
            },
            "pressed": {
              "value": "#c0d4f9"
            },
            "translucent": {
              "default": {
                "value": "rgba(30, 80, 205, 0.04)"
              },
              "hovered": {
                "value": "rgba(15, 85, 225, 0.1)"
              },
              "focused": {
                "value": "rgba(15, 85, 225, 0.1)"
              },
              "pressed": {
                "value": "rgba(3, 83, 231, 0.25)"
              }
            }
          },
          "tertiary": {
            "default": {
              "value": "#e7eefc",
              "comment": "Creates a slightly stronger contrast with the primary background while using the brand color. Use it to enhances the visual separation between different sections or elements."
            },
            "hovered": {
              "value": "#c0d4f9"
            },
            "focused": {
              "value": "#c0d4f9"
            },
            "pressed": {
              "value": "#7fadef"
            },
            "translucent": {
              "default": {
                "value": "rgba(15, 85, 225, 0.1)"
              },
              "hovered": {
                "value": "rgba(3, 83, 231, 0.25)"
              },
              "focused": {
                "value": "rgba(3, 83, 231, 0.25)"
              },
              "pressed": {
                "value": "rgba(4, 94, 224, 0.51);"
              }
            }
          },
          "solid": {
            "default": {
              "value": "#007acd",
              "comment": "Use to add a robust and substantial brand presence, making the elements stand out using the brand color."
            },
            "hovered": {
              "value": "#005aa0"
            },
            "focused": {
              "value": "#005aa0"
            },
            "pressed": {
              "value": "#004478"
            }
          },
          "spotlight": {
            "default": {
              "value": "#0b2d4e",
              "comment": "Creates a high contrast with the primary background while reminiscing of the brand identity. Use it for critical elements or components that require maximum emphasis and visual impact."
            }
          }
        },
        "status": {
          "danger": {
            "primary": {
              "translucent": {
                "default": {
                  "value": "rgba(0, 0, 0, 0)"
                },
                "hovered": {
                  "value": "rgba(255, 64, 19, 0.11)"
                },
                "focused": {
                  "value": "rgba(255, 64, 19, 0.11)"
                },
                "pressed": {
                  "value": "rgba(255, 48, 5, 0.28)"
                },
                "selected": {
                  "value": "rgba(255, 64, 19, 0.11)"
                }
              }
            },
            "secondary": {
              "default": {
                "value": "#fff7f5"
              },
              "hovered": {
                "value": "#ffeae5"
              },
              "focused": {
                "value": "#ffeae5"
              },
              "pressed": {
                "value": "#ffc5b9"
              }
            },
            "tertiary": {
              "default": {
                "value": "#ffeae5"
              },
              "hovered": {
                "value": "#ffc5b9"
              },
              "focused": {
                "value": "#ffc5b9"
              },
              "pressed": {
                "value": "#ff8979"
              },
              "translucent": {
                "default": {
                  "value": "rgba(255, 64, 19, 0.11)"
                },
                "hovered": {
                  "value": "rgba(255, 48, 5, 0.28)"
                },
                "focused": {
                  "value": "rgba(255, 48, 5, 0.28)"
                },
                "pressed": {
                  "value": "rgba(255, 32, 2, 0.53);"
                }
              }
            },
            "solid": {
              "default": {
                "value": "#db3735",
                "comment": "Indicate a state of danger or criticality in your interface. Use for elements that require immediate attention due to errors, warnings, or potentially harmful actions. Serves as a powerful visual cue to alert users to potential issues and encourages careful consideration of their actions."
              },
              "hovered": {
                "value": "#aa2424"
              },
              "pressed": {
                "value": "#7f1d1b"
              },
              "focused": {
                "value": "#aa2424"
              }
            },
            "spotlight": {
              "default": {
                "value": "#521814"
              }
            }
          },
          "success": {
            "primary": {
              "translucent": {
                "default": {
                  "value": "rgba(0, 0, 0, 0)"
                },
                "hovered": {
                  "value": "rgba(13, 155, 22, 0.12)"
                },
                "focused": {
                  "value": "rgba(13, 155, 22, 0.12)"
                },
                "pressed": {
                  "value": "rgba(2, 155, 18, 0.32)"
                },
                "selected": {
                  "value": "rgba(13, 155, 22, 0.12)"
                }
              }
            },
            "secondary": {
              "default": {
                "value": "#f3faf4"
              },
              "hovered": {
                "value": "#e2f3e3"
              },
              "focused": {
                "value": "#e2f3e3"
              },
              "pressed": {
                "value": "#aedfb3"
              }
            },
            "tertiary": {
              "default": {
                "value": "#e2f3e3"
              },
              "hovered": {
                "value": "#aedfb3"
              },
              "focused": {
                "value": "#aedfb3"
              },
              "pressed": {
                "value": "#63be74"
              },
              "translucent": {
                "default": {
                  "value": "rgba(13, 155, 22, 0.12)"
                },
                "hovered": {
                  "value": "rgba(2, 155, 18, 0.32)"
                },
                "focused": {
                  "value": "rgba(2, 155, 18, 0.32)"
                },
                "pressed": {
                  "value": "rgba(3, 150, 31, 0.62);"
                }
              }
            },
            "solid": {
              "default": {
                "value": "#008836",
                "comment": "Associated with successful or positive states in your interface. Apply it to elements or components that confirm successful actions or completion of tasks."
              },
              "hovered": {
                "value": "#006725"
              },
              "focused": {
                "value": "#006725"
              },
              "pressed": {
                "value": "#004d1c"
              }
            },
            "spotlight": {
              "default": {
                "value": "#0a3315"
              }
            }
          },
          "warning": {
            "primary": {
              "translucent": {
                "default": {
                  "value": "rgba(0, 0, 0, 0)"
                },
                "hovered": {
                  "value": "rgba(255, 115, 8, 0.15)"
                },
                "focused": {
                  "value": "rgba(255, 115, 8, 0.15)"
                },
                "pressed": {
                  "value": "rgba(255, 114, 4, 0.39)"
                },
                "selected": {
                  "value": "rgba(255, 115, 8, 0.15)"
                }
              }
            },
            "secondary": {
              "default": {
                "value": "#fff5ed"
              },
              "hovered": {
                "value": "#ffeada"
              },
              "focused": {
                "value": "#ffeada"
              },
              "pressed": {
                "value": "#ffc89d"
              }
            },
            "tertiary": {
              "default": {
                "value": "#ffeada"
              },
              "hovered": {
                "value": "#ffc89d"
              },
              "focused": {
                "value": "#ffc89d"
              },
              "pressed": {
                "value": "#ff8c47"
              },
              "translucent": {
                "default": {
                  "value": "rgba(255, 115, 8, 0.15)"
                },
                "hovered": {
                  "value": "rgba(255, 114, 4, 0.39)"
                },
                "focused": {
                  "value": "rgba(255, 114, 4, 0.39)"
                },
                "pressed": {
                  "value": "rgba(255, 97, 3, 0.73);"
                }
              }
            },
            "solid": {
              "default": {
                "value": "#ff8c47",
                "comment": "Conveys a warning or cautionary state in your interface. Use for elements that signify non-critical but important information, such as alerts, notices, or reminders. Helps users recognize situations that require their attention without implying immediate danger."
              },
              "hovered": {
                "value": "#d96929"
              },
              "focused": {
                "value": "#d96929"
              },
              "pressed": {
                "value": "#bc5921"
              }
            },
            "spotlight": {
              "default": {
                "value": "#442312"
              }
            }
          },
          "discovery": {
            "primary": {
              "translucent": {
                "default": {
                  "value": "rgba(0, 0, 0, 0)"
                },
                "hovered": {
                  "value": "rgba(93, 18, 155, 0.08)"
                },
                "focused": {
                  "value": "rgba(93, 18, 155, 0.08)"
                },
                "pressed": {
                  "value": "rgba(90, 0, 160, 0.20)"
                },
                "selected": {
                  "value": "rgba(93, 18, 155, 0.08)"
                }
              }
            },
            "secondary": {
              "default": {
                "value": "#faf7fc"
              },
              "hovered": {
                "value": "#f2ecf7"
              },
              "focused": {
                "value": "#f2ecf7"
              },
              "pressed": {
                "value": "#deccec"
              }
            },
            "tertiary": {
              "default": {
                "value": "#f2ecf7"
              },
              "hovered": {
                "value": "#deccec"
              },
              "focused": {
                "value": "#deccec"
              },
              "pressed": {
                "value": "#bf9ed9"
              },
              "translucent": {
                "default": {
                  "value": "rgba(93, 18, 155, 0.08)"
                },
                "hovered": {
                  "value": "rgba(90, 0, 160, 0.20)"
                },
                "focused": {
                  "value": "rgba(90, 0, 160, 0.20)"
                },
                "pressed": {
                  "value": "rgba(91, 6, 158, 0.39);"
                }
              }
            },
            "solid": {
              "default": {
                "value": "#9261b7",
                "comment": "Employed to highlight discovery or exploration-related content in your interface. Use to draw attention to new features, recommendations, or content suggestions. Invites users to explore and engage with new offerings, creating an exciting and engaging experience."
              },
              "hovered": {
                "value": "#744299"
              },
              "focused": {
                "value": "#744299"
              },
              "pressed": {
                "value": "#533669"
              }
            },
            "spotlight": {
              "default": {
                "value": "#312838"
              }
            }
          },
          "information": {
            "primary": {
              "translucent": {
                "default": {
                  "value": "rgba(0, 0, 0, 0)"
                },
                "hovered": {
                  "value": "rgba(15, 85, 225, 0.1)"
                },
                "focused": {
                  "value": "rgba(15, 85, 225, 0.1)"
                },
                "pressed": {
                  "value": "rgba(3, 83, 231, 0.25)"
                },
                "selected": {
                  "value": "rgba(15, 85, 225, 0.1)"
                }
              }
            },
            "solid": {
              "default": {
                "value": "#007acd",
                "comment": "Used for informational elements in your interface. Conveys non-critical information or provides additional details. Use for components or supplementary content that enhances the user's understanding of the interface without causing distraction."
              },
              "hovered": {
                "value": "#005aa0"
              },
              "focused": {
                "value": "#005aa0"
              },
              "pressed": {
                "value": "#004478"
              }
            },
            "secondary": {
              "default": {
                "value": "#f6f8fd"
              },
              "hovered": {
                "value": "#e7eefc"
              },
              "focused": {
                "value": "#e7eefc"
              },
              "pressed": {
                "value": "#c0d4f9"
              }
            },
            "tertiary": {
              "default": {
                "value": "#e7eefc"
              },
              "hovered": {
                "value": "#c0d4f9"
              },
              "focused": {
                "value": "#c0d4f9"
              },
              "pressed": {
                "value": "#7fadef"
              },
              "translucent": {
                "default": {
                  "value": "rgba(15, 85, 225, 0.1)"
                },
                "hovered": {
                  "value": "rgba(3, 83, 231, 0.25)"
                },
                "focused": {
                  "value": "rgba(3, 83, 231, 0.25)"
                },
                "pressed": {
                  "value": "rgba(4, 94, 224, 0.51);"
                }
              }
            },
            "spotlight": {
              "default": {
                "value": "#0b2d4e"
              }
            }
          },
          "planet": {
            "primary": {
              "translucent": {
                "default": {
                  "value": "rgba(0, 0, 0, 0)"
                },
                "hovered": {
                  "value": "rgba(8, 182, 175, 0.15)"
                },
                "focused": {
                  "value": "rgba(8, 182, 175, 0.15)"
                },
                "pressed": {
                  "value": "rgba(3, 179, 174, 0.42)"
                },
                "selected": {
                  "value": "rgba(8, 182, 175, 0.15)"
                }
              }
            },
            "solid": {
              "default": {
                "value": "#008480"
              },
              "hovered": {
                "value": "#0a6361"
              },
              "pressed": {
                "value": "#154948"
              },
              "focused": {
                "value": "#0a6361"
              }
            },
            "secondary": {
              "default": {
                "value": "#f1fafa"
              },
              "hovered": {
                "value": "#daf4f3"
              },
              "focused": {
                "value": "#daf4f3"
              },
              "pressed": {
                "value": "#95dfdd"
              }
            },
            "tertiary": {
              "default": {
                "value": "#daf4f3"
              },
              "hovered": {
                "value": "#95dfdd"
              },
              "focused": {
                "value": "#95dfdd"
              },
              "pressed": {
                "value": "#3dbcb8"
              },
              "translucent": {
                "default": {
                  "value": "rgba(8, 182, 175, 0.15)"
                },
                "hovered": {
                  "value": "rgba(3, 179, 174, 0.42)"
                },
                "focused": {
                  "value": "rgba(3, 179, 174, 0.42)"
                },
                "pressed": {
                  "value": "rgba(3, 168, 163, 0.77);"
                }
              }
            },
            "spotlight": {
              "default": {
                "value": "#192f2f"
              }
            }
          }
        },
        "accent": {
          "pink": {
            "secondary": {
              "default": {
                "value": "#fff4f9",
                "comment": "Subtle touch of pink with no meaning tied to the color."
              },
              "hovered": {
                "value": "#ffe9f3"
              },
              "focused": {
                "value": "#ffe9f3"
              },
              "pressed": {
                "value": "#ffc1df"
              }
            },
            "tertiary": {
              "default": {
                "value": "#ffe9f3",
                "comment": "Soft pink background with no meaning tied to the color, slightly stronger visual contrast than the secondary."
              },
              "hovered": {
                "value": "#ffc1df"
              },
              "focused": {
                "value": "#ffc1df"
              },
              "pressed": {
                "value": "#ff80bd"
              }
            },
            "solid": {
              "default": {
                "value": "#e01b78",
                "comment": "Suitable for pink backgrounds when there is no meaning tied to the color. Ensures the minimum contrast requirements (at least 4.55:1)."
              },
              "hovered": {
                "value": "#963563"
              },
              "focused": {
                "value": "#963563"
              },
              "pressed": {
                "value": "#5c3749"
              }
            },
            "spotlight": {
              "default": {
                "value": "#34282e",
                "comment": "Reserved for highlighting and drawing maximum attention when there is no meaning tied to the color. Use sparingly to create important focal points in your interface"
              }
            }
          },
          "red": {
            "secondary": {
              "default": {
                "value": "#fff7f5",
                "comment": "Subtle touch of red with no meaning tied to the color."
              },
              "hovered": {
                "value": "#ffeae5"
              },
              "focused": {
                "value": "#ffeae5"
              },
              "pressed": {
                "value": "#ffc5b9"
              }
            },
            "tertiary": {
              "default": {
                "value": "#ffeae5",
                "comment": "Soft red background with no meaning tied to the color, slightly stronger visual contrast than the secondary."
              },
              "hovered": {
                "value": "#ffc5b9"
              },
              "focused": {
                "value": "#ffc5b9"
              },
              "pressed": {
                "value": "#ff8979"
              }
            },
            "solid": {
              "default": {
                "value": "#db3735",
                "comment": "Suitable for red backgrounds when there is no meaning tied to the color. Ensures the minimum contrast requirements (at least 4.55:1)."
              }
            },
            "spotlight": {
              "default": {
                "value": "#521814",
                "comment": "Reserved for highlighting and drawing maximum attention when there is no meaning tied to the color. Use sparingly to create important focal points in your interface"
              }
            }
          },
          "orange": {
            "secondary": {
              "default": {
                "value": "#fff5ed",
                "comment": "Subtle touch of orange with no meaning tied to the color."
              },
              "hovered": {
                "value": "#ffeada"
              },
              "focused": {
                "value": "#ffeada"
              },
              "pressed": {
                "value": "#ffc89d"
              }
            },
            "tertiary": {
              "default": {
                "value": "#ffeada",
                "comment": "Soft orange background with no meaning tied to the color, slightly stronger visual contrast than the secondary."
              },
              "hovered": {
                "value": "#ffc89d"
              },
              "focused": {
                "value": "#ffc89d"
              },
              "pressed": {
                "value": "#ff8c47"
              }
            },
            "solid": {
              "default": {
                "value": "#bc5921",
                "comment": "Suitable for orange backgrounds when there is no meaning tied to the color. Ensures the minimum contrast requirements (at least 4.55:1)."
              },
              "hovered": {
                "value": "#8e441a"
              },
              "focused": {
                "value": "#8e441a"
              },
              "pressed": {
                "value": "#693315"
              }
            },
            "spotlight": {
              "default": {
                "value": "#442312",
                "comment": "Reserved for highlighting and drawing maximum attention when there is no meaning tied to the color. Use sparingly to create important focal points in your interface"
              }
            }
          },
          "yellow": {
            "secondary": {
              "default": {
                "value": "#fff7e0",
                "comment": "Subtle touch of yellow with no meaning tied to the color."
              },
              "hovered": {
                "value": "#ffeeb4"
              },
              "focused": {
                "value": "#ffeeb4"
              },
              "pressed": {
                "value": "#ffcc59"
              }
            },
            "tertiary": {
              "default": {
                "value": "#ffeeb4",
                "comment": "Soft yellow background with no meaning tied to the color, slightly stronger visual contrast than the secondary."
              },
              "hovered": {
                "value": "#ffcc59"
              },
              "focused": {
                "value": "#ffcc59"
              },
              "pressed": {
                "value": "#d5a334"
              }
            },
            "solid": {
              "default": {
                "value": "#ffcc59",
                "comment": "Suitable for yellow backgrounds when there is no meaning tied to the color. No minimum contrast requirement met!"
              },
              "hovered": {
                "value": "#d5a334"
              },
              "focused": {
                "value": "#d5a334"
              },
              "pressed": {
                "value": "#ab8327"
              }
            },
            "spotlight": {
              "default": {
                "value": "#362a13",
                "comment": "Reserved for highlighting and drawing maximum attention when there is no meaning tied to the color. Use sparingly to create important focal points in your interface"
              }
            }
          },
          "green": {
            "secondary": {
              "default": {
                "value": "#f3faf4",
                "comment": "Subtle touch of green with no meaning tied to the color."
              },
              "hovered": {
                "value": "#e2f3e3"
              },
              "focused": {
                "value": "#e2f3e3"
              },
              "pressed": {
                "value": "#aedfb3"
              }
            },
            "tertiary": {
              "default": {
                "value": "#e2f3e3",
                "comment": "Soft green background with no meaning tied to the color, slightly stronger visual contrast than the secondary."
              },
              "hovered": {
                "value": "#aedfb3"
              },
              "focused": {
                "value": "#aedfb3"
              },
              "pressed": {
                "value": "#63be74"
              }
            },
            "solid": {
              "default": {
                "value": "#008836",
                "comment": "Suitable for green backgrounds when there is no meaning tied to the color. Ensures the minimum contrast requirements (at least 4.55:1)."
              },
              "hovered": {
                "value": "#006725"
              },
              "focused": {
                "value": "#006725"
              },
              "pressed": {
                "value": "#004d1c"
              }
            },
            "spotlight": {
              "default": {
                "value": "#0a3315",
                "comment": "Reserved for highlighting and drawing maximum attention when there is no meaning tied to the color. Use sparingly to create important focal points in your interface"
              }
            }
          },
          "grey": {
            "secondary": {
              "default": {
                "value": "#f6f8f9",
                "comment": "Subtle touch of grey with no meaning tied to the color."
              },
              "hovered": {
                "value": "#ebeef1"
              },
              "focused": {
                "value": "#ebeef1"
              },
              "pressed": {
                "value": "#ccd4da"
              }
            },
            "tertiary": {
              "default": {
                "value": "#ebeef1",
                "comment": "Soft grey background with no meaning tied to the color, slightly stronger visual contrast than the secondary."
              },
              "hovered": {
                "value": "#ccd4da"
              },
              "focused": {
                "value": "#ccd4da"
              },
              "pressed": {
                "value": "#9eadb8"
              }
            },
            "solid": {
              "default": {
                "value": "#60798b",
                "comment": "Suitable for grey backgrounds when there is no meaning tied to the color. Ensures the minimum contrast requirements (at least 4.55:1)."
              },
              "hovered": {
                "value": "#435c6e"
              },
              "focused": {
                "value": "#435c6e"
              },
              "pressed": {
                "value": "#334551"
              }
            },
            "spotlight": {
              "default": {
                "value": "#232d35",
                "comment": "Reserved for highlighting and drawing maximum attention when there is no meaning tied to the color. Use sparingly to create important focal points in your interface"
              }
            }
          },
          "blue": {
            "secondary": {
              "default": {
                "value": "#f6f8fd",
                "comment": "Subtle touch of blue with no meaning tied to the color."
              },
              "hovered": {
                "value": "#e7eefc"
              },
              "focused": {
                "value": "#e7eefc"
              },
              "pressed": {
                "value": "#c0d4f9"
              }
            },
            "tertiary": {
              "default": {
                "value": "#e7eefc",
                "comment": "Soft blue background with no meaning tied to the color, slightly stronger visual contrast than the secondary."
              },
              "hovered": {
                "value": "#c0d4f9"
              },
              "focused": {
                "value": "#c0d4f9"
              },
              "pressed": {
                "value": "#7fadef"
              }
            },
            "solid": {
              "default": {
                "value": "#007acd",
                "comment": "Suitable for blue backgrounds when there is no meaning tied to the color. Ensures the minimum contrast requirements (at least 4.55:1)."
              },
              "hovered": {
                "value": "#005aa0"
              },
              "focused": {
                "value": "#005aa0"
              },
              "pressed": {
                "value": "#004478"
              }
            },
            "spotlight": {
              "default": {
                "value": "#0b2d4e",
                "comment": "Reserved for highlighting and drawing maximum attention when there is no meaning tied to the color. Use sparingly to create important focal points in your interface"
              }
            }
          },
          "ultramarine": {
            "secondary": {
              "default": {
                "value": "#f6f7fb",
                "comment": "Subtle touch of ultramarine with no meaning tied to the color."
              },
              "hovered": {
                "value": "#eceef7"
              },
              "focused": {
                "value": "#eceef7"
              },
              "pressed": {
                "value": "#ccd1e9"
              }
            },
            "tertiary": {
              "default": {
                "value": "#eceef7",
                "comment": "Soft ultramarine background with no meaning tied to the color, slightly stronger visual contrast than the secondary."
              },
              "hovered": {
                "value": "#ccd1e9"
              },
              "focused": {
                "value": "#ccd1e9"
              },
              "pressed": {
                "value": "#9fa9d4"
              }
            },
            "solid": {
              "default": {
                "value": "#6372b2",
                "comment": "Suitable for ultramarine backgrounds when there is no meaning tied to the color. Ensures the minimum contrast requirements (at least 4.55:1)."
              },
              "hovered": {
                "value": "#44549b"
              },
              "focused": {
                "value": "#44549b"
              },
              "pressed": {
                "value": "#2b3d83"
              }
            },
            "spotlight": {
              "default": {
                "value": "#182663",
                "comment": "Reserved for highlighting and drawing maximum attention when there is no meaning tied to the color. Use sparingly to create important focal points in your interface"
              }
            }
          },
          "purple": {
            "secondary": {
              "default": {
                "value": "#faf7fc",
                "comment": "Subtle touch of purple with no meaning tied to the color."
              },
              "hovered": {
                "value": "#f2ecf7"
              },
              "focused": {
                "value": "#f2ecf7"
              },
              "pressed": {
                "value": "#deccec"
              }
            },
            "tertiary": {
              "default": {
                "value": "#f2ecf7",
                "comment": "Soft purple background with no meaning tied to the color, slightly stronger visual contrast than the secondary."
              },
              "hovered": {
                "value": "#deccec"
              },
              "focused": {
                "value": "#deccec"
              },
              "pressed": {
                "value": "#bf9ed9"
              }
            },
            "solid": {
              "default": {
                "value": "#9261b7",
                "comment": "Suitable for purple backgrounds when there is no meaning tied to the color. Ensures the minimum contrast requirements (at least 4.55:1)."
              },
              "hovered": {
                "value": "#744299"
              },
              "focused": {
                "value": "#744299"
              },
              "pressed": {
                "value": "#533669"
              }
            },
            "spotlight": {
              "default": {
                "value": "#312838",
                "comment": "Reserved for highlighting and drawing maximum attention when there is no meaning tied to the color. Use sparingly to create important focal points in your interface"
              }
            }
          },
          "teal": {
            "secondary": {
              "default": {
                "value": "#f1fafa",
                "comment": "Subtle touch of teal with no meaning tied to the color."
              },
              "hovered": {
                "value": "#daf4f3"
              },
              "focused": {
                "value": "#daf4f3"
              },
              "pressed": {
                "value": "#95dfdd"
              }
            },
            "tertiary": {
              "default": {
                "value": "#daf4f3",
                "comment": "Soft teal background with no meaning tied to the color, slightly stronger visual contrast than the secondary."
              },
              "hovered": {
                "value": "#95dfdd"
              },
              "focused": {
                "value": "#95dfdd"
              },
              "pressed": {
                "value": "#3dbcb8"
              }
            },
            "solid": {
              "default": {
                "value": "#008480",
                "comment": "Suitable for teal backgrounds when there is no meaning tied to the color. Ensures the minimum contrast requirements (at least 4.55:1)."
              },
              "hovered": {
                "value": "#0a6361"
              },
              "focused": {
                "value": "#0a6361"
              },
              "pressed": {
                "value": "#154948"
              }
            },
            "spotlight": {
              "default": {
                "value": "#192f2f",
                "comment": "Reserved for highlighting and drawing maximum attention when there is no meaning tied to the color. Use sparingly to create important focal points in your interface"
              }
            }
          },
          "lime": {
            "secondary": {
              "default": {
                "value": "#f8fad5",
                "comment": "Subtle touch of lime with no meaning tied to the color."
              },
              "hovered": {
                "value": "#eef5a8"
              },
              "focused": {
                "value": "#eef5a8"
              },
              "pressed": {
                "value": "#c7de43"
              }
            },
            "tertiary": {
              "default": {
                "value": "#eef5a8",
                "comment": "Soft lime background with no meaning tied to the color, slightly stronger visual contrast than the secondary."
              },
              "hovered": {
                "value": "#c7de43"
              },
              "focused": {
                "value": "#c7de43"
              },
              "pressed": {
                "value": "#9db603"
              }
            },
            "solid": {
              "default": {
                "value": "#6c7d02",
                "comment": "Suitable for lime backgrounds when there is no meaning tied to the color. Ensures the minimum contrast requirements (at least 4.55:1)."
              },
              "hovered": {
                "value": "#525f0a"
              },
              "focused": {
                "value": "#525f0a"
              },
              "pressed": {
                "value": "#3e460e"
              }
            },
            "spotlight": {
              "default": {
                "value": "#2a2e0e",
                "comment": "Reserved for highlighting and drawing maximum attention when there is no meaning tied to the color. Use sparingly to create important focal points in your interface"
              }
            }
          }
        },
        "additional": {
          "engieRay": {
            "default": {
              "value": "linear-gradient(90deg, #00AAFF 0%, #23d2b5 100%)",
              "comment": "ENGIE's brand gradient. We strongly advise to refer to the brand guidelines for its usage."
            }
          },
          "signature": {
            "default": {
              "value": "#182663",
              "comment": "ENGIE's additional brand color. We strongly advise to refer to the brand guidelines for its usage."
            }
          }
        }
      },
      "border": {
        "neutral": {
          "minimal": {
            "value": "#ebeef1",
            "comment": "Almost imperceptible, provides a gentle visual separation between elements without drawing much attention to itself. Used for separators."
          },
          "subtle": {
            "value": "#ccd4da",
            "comment": "Offers a subtle visual distinction between elements, without overpowering the content. Used for separators and cards."
          },
          "moderate": {
            "value": "#9eadb8",
            "comment": "Provides a clear separation between elements, ensuring they stand out without becoming too overwhelming. Use for tags and inputs."
          },
          "strong": {
            "value": "#60798b",
            "comment": "Bold border, creates a striking visual division between elements. Use for notifications, inline messages and focus ring."
          },
          "heavy": {
            "value": "#232d35",
            "comment": "Highest level of visual differentiation. Has a strong contrast with the background, making it visually prominent and attention-grabbing. Use for important alerts."
          },
          "inverse": {
            "value": "#ffffff",
            "comment": "Allows the application of a border when used on a contrasted background. Use for inverse buttons or inverse cards."
          }
        },
        "brand": {
          "subtle": {
            "value": "#c0d4f9",
            "comment": "Provides a gentle visual separation between elements, with a touch of Engie's brand identity.Suitable for elements where a subtle brand presence is desired."
          },
          "moderate": {
            "value": "#7fadef",
            "comment": "Provides a clear separation between elements with the brand identity. Use for tags and inputs."
          },
          "strong": {
            "value": "#007acd",
            "comment": "Bold brand border, creates a striking visual division between elements. Use for borders or visual indicators of elements that reinforce Engie's brand, such as notifications, inline messages and focus ring."
          }
        },
        "status": {
          "danger": {
            "subtle": {
              "value": "#ffc5b9",
              "comment": "Subtle way to communicate critical information without overpowering the content. Use for separators and cards."
            },
            "moderate": {
              "value": "#ff8979",
              "comment": "Communicates critical information in your interface. Provides a clear separation between elements, ensuring they stand out without becoming too overwhelming. Use for tags and inputs."
            },
            "strong": {
              "value": "#db3735",
              "comment": "Intended to indicate a state of danger or criticality in your interface. Use for elements that require immediate attention due to errors, warnings, or potentially harmful actions. Serves as a powerful visual cue to alert users to potential issues and encourages careful consideration of their actions."
            }
          },
          "warning": {
            "subtle": {
              "value": "#ffc89d",
              "comment": "Subtle way to communicate warning information without overpowering the content. Use for separators and cards."
            },
            "moderate": {
              "value": "#ff8c47",
              "comment": "Communicates warning information in your interface. Provides a clear separation between elements, ensuring they stand out without becoming too overwhelming."
            },
            "strong": {
              "value": "#bc5921",
              "comment": "Conveys a warning or cautionary state in your interface. Use for elements that signify non-critical but important information, such as alerts, notices, or reminders. Helps users recognize situations that require their attention without implying immediate danger."
            }
          },
          "success": {
            "subtle": {
              "value": "#aedfb3",
              "comment": "Subtle way to communicate success information or positive states without overpowering the content. Use for separators and cards."
            },
            "moderate": {
              "value": "#63be74",
              "comment": "Communicates successful or positive states in your interface. Provides a clear separation between elements, ensuring they stand out without becoming too overwhelming."
            },
            "strong": {
              "value": "#008836",
              "comment": "Associated with successful or positive states in your interface. Apply it to elements or components that confirm successful actions or completion of tasks."
            }
          },
          "discovery": {
            "subtle": {
              "value": "#deccec",
              "comment": "Subtle way to highlight discovery or exploration-related content in your interface without overpowering the content. Use for separators and cards."
            },
            "moderate": {
              "value": "#bf9ed9",
              "comment": "Highlights discovery or communicates exploration-related content in your interface. Provides a clear separation between elements, ensuring they stand out without becoming too overwhelming."
            },
            "strong": {
              "value": "#9261b7",
              "comment": "Employed to highlight discovery or exploration-related content in your interface. Use to draw attention to new features, recommendations, or content suggestions. Invites users to explore and engage with new offerings, creating an exciting and engaging experience."
            }
          },
          "information": {
            "subtle": {
              "value": "#c0d4f9",
              "comment": "Subtle way to highlight informational elements in your interface without overpowering the content. Use for separators and cards."
            },
            "moderate": {
              "value": "#7fadef",
              "comment": "Highlights informational elements in your interface. Provides a clear separation between elements, ensuring they stand out without becoming too overwhelming."
            },
            "strong": {
              "value": "#007acd",
              "comment": "Used for informational elements in your interface. Conveys non-critical information or provides additional details. Use for components or supplementary content that enhances the user's understanding of the interface without causing distraction."
            }
          },
          "planet": {
            "subtle": {
              "value": "#95dfdd"
            },
            "moderate": {
              "value": "#3dbcb8"
            },
            "strong": {
              "value": "#008480"
            }
          }
        },
        "accent": {
          "pink": {
            "subtle": {
              "value": "#ffc1df"
            },
            "moderate": {
              "value": "#ff80bd",
              "comment": "Suitable for pink borders when there is no meaning tied to the color."
            },
            "strong": {
              "value": "#e01b78"
            }
          },
          "red": {
            "subtle": {
              "value": "#ffc5b9"
            },
            "moderate": {
              "value": "#ff8979",
              "comment": "Suitable for red borders when there is no meaning tied to the color."
            },
            "strong": {
              "value": "#db3735"
            }
          },
          "orange": {
            "subtle": {
              "value": "#ffc89d"
            },
            "moderate": {
              "value": "#ff8c47",
              "comment": "Suitable for orange borders when there is no meaning tied to the color."
            },
            "strong": {
              "value": "#bc5921"
            }
          },
          "yellow": {
            "subtle": {
              "value": "#ffcc59"
            },
            "moderate": {
              "value": "#d5a334",
              "comment": "Suitable for yellow borders when there is no meaning tied to the color."
            },
            "strong": {
              "value": "#947023"
            }
          },
          "green": {
            "subtle": {
              "value": "#aedfb3"
            },
            "moderate": {
              "value": "#63be74",
              "comment": "Suitable for green borders when there is no meaning tied to the color."
            },
            "strong": {
              "value": "#008836"
            }
          },
          "grey": {
            "subtle": {
              "value": "#ccd4da"
            },
            "moderate": {
              "value": "#9eadb8",
              "comment": "Suitable for grey borders when there is no meaning tied to the color."
            },
            "strong": {
              "value": "#60798b"
            }
          },
          "blue": {
            "subtle": {
              "value": "#c0d4f9"
            },
            "moderate": {
              "value": "#7fadef",
              "comment": "Suitable for blue borders when there is no meaning tied to the color."
            },
            "strong": {
              "value": "#007acd"
            }
          },
          "ultramarine": {
            "subtle": {
              "value": "#ccd1e9"
            },
            "moderate": {
              "value": "#9fa9d4",
              "comment": "Suitable for ultramarine borders when there is no meaning tied to the color."
            },
            "strong": {
              "value": "#6372b2"
            }
          },
          "purple": {
            "subtle": {
              "value": "#deccec"
            },
            "moderate": {
              "value": "#bf9ed9",
              "comment": "Suitable for purple borders when there is no meaning tied to the color."
            },
            "strong": {
              "value": "#9261b7"
            }
          },
          "teal": {
            "subtle": {
              "value": "#95dfdd"
            },
            "moderate": {
              "value": "#3dbcb8",
              "comment": "Suitable for teal borders when there is no meaning tied to the color."
            },
            "strong": {
              "value": "#008480"
            }
          },
          "lime": {
            "subtle": {
              "value": "#c7de43"
            },
            "moderate": {
              "value": "#9db603",
              "comment": "Suitable for lime borders when there is no meaning tied to the color."
            },
            "strong": {
              "value": "#6c7d02"
            }
          }
        }
      },
      "icon": {
        "neutral": {
          "primary": {
            "default": {
              "value": "#334551",
              "comment": "Use for visible and prominent icons, ensures they are easily recognizable and stand out. Typically used for essential actions or navigation elements that require immediate user attention."
            }
          },
          "secondary": {
            "default": {
              "value": "#60798b",
              "comment": "Use for less visible icons, offering a subtle presence that complements the primary content. Used for secondary actions or to support elements within the interface."
            },
            "hovered": {
              "value": "#435c6e"
            },
            "focused": {
              "value": "#435c6e"
            },
            "pressed": {
              "value": "#334551"
            }
          },
          "tertiary": {
            "default": {
              "value": "#778c9b",
              "comment": "Use for icons with even lower visibility, providing a more understated appearance. These icons are used for tertiary actions or optional elements, contributing to a more streamlined and unobtrusive user experience."
            },
            "hovered": {
              "value": "#60798b"
            },
            "focused": {
              "value": "#60798b"
            },
            "pressed": {
              "value": "#435c6e"
            }
          },
          "contrast": {
            "default": {
              "value": "#435c6e",
              "comment": "These icons utilizes higher contrast or distinct visual treatments to create emphasis and draw attention. It is employed for icons that require enhanced visibility or a stronger visual impact."
            },
            "hovered": {
              "value": "#334551"
            },
            "focused": {
              "value": "#334551"
            },
            "pressed": {
              "value": "#232d35"
            }
          },
          "inverse": {
            "default": {
              "value": "#ffffff",
              "comment": "Use for icons on dark backgrounds. Ensures readability and visual appeal, particularly in scenarios where icons are placed on contrasting surfaces."
            }
          }
        },
        "brand": {
          "primary": {
            "default": {
              "value": "#007acd"
            },
            "hovered": {
              "value": "#005aa0"
            },
            "focused": {
              "value": "#005aa0"
            },
            "pressed": {
              "value": "#004478"
            }
          },
          "contrast": {
            "default": {
              "value": "#004478"
            }
          }
        },
        "status": {
          "danger": {
            "primary": {
              "default": {
                "value": "#db3735"
              },
              "hovered": {
                "value": "#aa2424"
              },
              "focused": {
                "value": "#aa2424"
              },
              "pressed": {
                "value": "#7f1d1b"
              }
            },
            "contrast": {
              "default": {
                "value": "#7f1d1b"
              }
            }
          },
          "success": {
            "primary": {
              "default": {
                "value": "#008836"
              },
              "hovered": {
                "value": "#006725"
              },
              "focused": {
                "value": "#006725"
              },
              "pressed": {
                "value": "#004d1c"
              }
            },
            "contrast": {
              "default": {
                "value": "#004d1c"
              }
            }
          },
          "warning": {
            "primary": {
              "default": {
                "value": "#bc5921"
              },
              "hovered": {
                "value": "#8e441a"
              },
              "focused": {
                "value": "#8e441a"
              },
              "pressed": {
                "value": "#693315"
              }
            },
            "contrast": {
              "default": {
                "value": "#693315"
              }
            }
          },
          "discovery": {
            "primary": {
              "default": {
                "value": "#9261b7"
              },
              "hovered": {
                "value": "#744299"
              },
              "focused": {
                "value": "#744299"
              },
              "pressed": {
                "value": "#533669"
              }
            },
            "contrast": {
              "default": {
                "value": "#533669"
              }
            }
          },
          "information": {
            "primary": {
              "default": {
                "value": "#007acd"
              },
              "hovered": {
                "value": "#005aa0"
              },
              "focused": {
                "value": "#005aa0"
              },
              "pressed": {
                "value": "#004478"
              }
            },
            "contrast": {
              "default": {
                "value": "#004478"
              }
            }
          },
          "planet": {
            "primary": {
              "default": {
                "value": "#008480"
              },
              "hovered": {
                "value": "#0a6361"
              },
              "focused": {
                "value": "#0a6361"
              },
              "pressed": {
                "value": "#154948"
              }
            },
            "contrast": {
              "default": {
                "value": "#154948"
              }
            }
          }
        },
        "accent": {
          "pink": {
            "primary": {
              "default": {
                "value": "#e01b78"
              },
              "hovered": {
                "value": "#963563"
              },
              "focused": {
                "value": "#963563"
              },
              "pressed": {
                "value": "#5c3749"
              }
            },
            "contrast": {
              "default": {
                "value": "#5c3749"
              }
            }
          },
          "red": {
            "primary": {
              "default": {
                "value": "#db3735"
              },
              "hovered": {
                "value": "#aa2424"
              },
              "focused": {
                "value": "#aa2424"
              },
              "pressed": {
                "value": "#7f1d1b"
              }
            },
            "contrast": {
              "default": {
                "value": "#7f1d1b"
              }
            }
          },
          "orange": {
            "primary": {
              "default": {
                "value": "#bc5921"
              },
              "hovered": {
                "value": "#8e441a"
              },
              "focused": {
                "value": "#8e441a"
              },
              "pressed": {
                "value": "#693315"
              }
            },
            "contrast": {
              "default": {
                "value": "#693315"
              }
            }
          },
          "yellow": {
            "primary": {
              "default": {
                "value": "#947023"
              },
              "hovered": {
                "value": "#6f551d"
              },
              "focused": {
                "value": "#6f551d"
              },
              "pressed": {
                "value": "#533f18"
              }
            },
            "contrast": {
              "default": {
                "value": "#533f18"
              }
            }
          },
          "green": {
            "primary": {
              "default": {
                "value": "#008836"
              },
              "hovered": {
                "value": "#006725"
              },
              "focused": {
                "value": "#006725"
              },
              "pressed": {
                "value": "#004d1c"
              }
            },
            "contrast": {
              "default": {
                "value": "#004d1c"
              }
            }
          },
          "grey": {
            "primary": {
              "default": {
                "value": "#60798b"
              },
              "hovered": {
                "value": "#435c6e"
              },
              "focused": {
                "value": "#435c6e"
              },
              "pressed": {
                "value": "#334551"
              }
            },
            "contrast": {
              "default": {
                "value": "#334551"
              }
            }
          },
          "blue": {
            "primary": {
              "default": {
                "value": "#007acd"
              },
              "hovered": {
                "value": "#005aa0"
              },
              "focused": {
                "value": "#005aa0"
              },
              "pressed": {
                "value": "#004478"
              }
            },
            "contrast": {
              "default": {
                "value": "#004478"
              }
            }
          },
          "ultramarine": {
            "primary": {
              "default": {
                "value": "#6372b2"
              },
              "hovered": {
                "value": "#44549b"
              },
              "focused": {
                "value": "#44549b"
              },
              "pressed": {
                "value": "#2b3d83"
              }
            },
            "contrast": {
              "default": {
                "value": "#2b3d83"
              }
            }
          },
          "purple": {
            "primary": {
              "default": {
                "value": "#9261b7"
              },
              "hovered": {
                "value": "#744299"
              },
              "focused": {
                "value": "#744299"
              },
              "pressed": {
                "value": "#533669"
              }
            },
            "contrast": {
              "default": {
                "value": "#533669"
              }
            }
          },
          "teal": {
            "primary": {
              "default": {
                "value": "#008480"
              },
              "hovered": {
                "value": "#0a6361"
              },
              "focused": {
                "value": "#0a6361"
              },
              "pressed": {
                "value": "#154948"
              }
            },
            "contrast": {
              "default": {
                "value": "#154948"
              }
            }
          },
          "lime": {
            "primary": {
              "default": {
                "value": "#6c7d02"
              },
              "hovered": {
                "value": "#525f0a"
              },
              "focused": {
                "value": "#525f0a"
              },
              "pressed": {
                "value": "#3e460e"
              }
            },
            "contrast": {
              "default": {
                "value": "#3e460e"
              }
            }
          }
        },
        "additional": {
          "signature": {
            "default": {
              "value": "#182663"
            }
          }
        }
      },
      "text": {
        "neutral": {
          "primary": {
            "default": {
              "value": "#171d21",
              "comment": "Reserved for the most critical and visually prominent content in the interface. Should be employed for elements like page titles and section headings, where it commands attention and establishes hierarchy."
            },
            "hovered": {
              "value": "#007acd"
            },
            "focused": {
              "value": "#007acd"
            },
            "pressed": {
              "value": "#005aa0"
            },
            "notInvertible": {
              "default": {
                "value": "#171d21"
              },
              "hovered": {
                "value": "#007acd"
              },
              "focused": {
                "value": "#007acd"
              },
              "pressed": {
                "value": "#005aa0"
              }
            }
          },
          "secondary": {
            "default": {
              "value": "#334551",
              "comment": "Harmonious and legible contrast for various content types. Well-suited for subtitles, subheadings, and regular content, ensures a balanced and readable appearance throughout the interface."
            }
          },
          "tertiary": {
            "default": {
              "value": "#60798b",
              "comment": "Slightly lighter, suitable for secondary subtitles or contextual information. Ideal for additional information."
            },
            "hovered": {
              "value": "#007acd"
            },
            "focused": {
              "value": "#007acd"
            },
            "pressed": {
              "value": "#005aa0"
            }
          },
          "contrast": {
            "default": {
              "value": "#435c6e",
              "comment": "Emphasizes elements with higher contrast to create visual hierarchy and draw attention."
            },
            "hovered": {
              "value": "#004478"
            },
            "focused": {
              "value": "#004478"
            },
            "pressed": {
              "value": "#0b2d4e"
            }
          },
          "inverse": {
            "default": {
              "value": "#ffffff",
              "comment": "Used for light text on a dark background for readability and visual appeal in specific UI components."
            },
            "hovered": {
              "value": "#ebeef1"
            },
            "focused": {
              "value": "#ebeef1"
            },
            "pressed": {
              "value": "#ccd4da"
            },
            "notInvertible": {
              "default": {
                "value": "#ffffff"
              },
              "hovered": {
                "value": "#ebeef1"
              },
              "focused": {
                "value": "#ebeef1"
              },
              "pressed": {
                "value": "#ccd4da"
              }
            }
          }
        },
        "brand": {
          "primary": {
            "default": {
              "value": "#007acd",
              "comment": "Powerful tool to reinforce the brand's presence and create visual emphasis. Use strategically for text elements that are central to the brand identity or require heightened attention, such as brand names, key headlines, or links."
            },
            "hovered": {
              "value": "#005aa0"
            },
            "focused": {
              "value": "#005aa0"
            },
            "pressed": {
              "value": "#004478"
            }
          },
          "contrast": {
            "default": {
              "value": "#005aa0",
              "comment": "Used on secondary backgrounds to keep the the contrast ratio for accessibility reasons."
            },
            "hovered": {
              "value": "#004478"
            },
            "focused": {
              "value": "#004478"
            },
            "pressed": {
              "value": "#0b2d4e"
            }
          }
        },
        "status": {
          "danger": {
            "primary": {
              "default": {
                "value": "#db3735"
              },
              "hovered": {
                "value": "#aa2424"
              },
              "focused": {
                "value": "#aa2424"
              },
              "pressed": {
                "value": "#7f1d1b"
              }
            },
            "contrast": {
              "default": {
                "value": "#aa2424"
              },
              "hovered": {
                "value": "#7f1d1b"
              },
              "focused": {
                "value": "#7f1d1b"
              },
              "pressed": {
                "value": "#521814"
              }
            }
          },
          "warning": {
            "primary": {
              "default": {
                "value": "#bc5921"
              },
              "hovered": {
                "value": "#8e441a"
              },
              "focused": {
                "value": "#8e441a"
              },
              "pressed": {
                "value": "#693315"
              }
            },
            "contrast": {
              "default": {
                "value": "#8e441a"
              },
              "hovered": {
                "value": "#693315"
              },
              "focused": {
                "value": "#693315"
              },
              "pressed": {
                "value": "#442312"
              }
            }
          },
          "success": {
            "primary": {
              "default": {
                "value": "#008836"
              },
              "hovered": {
                "value": "#006725"
              },
              "focused": {
                "value": "#006725"
              },
              "pressed": {
                "value": "#004d1c"
              }
            },
            "contrast": {
              "default": {
                "value": "#006725"
              },
              "hovered": {
                "value": "#004d1c"
              },
              "focused": {
                "value": "#004d1c"
              },
              "pressed": {
                "value": "#0a3315"
              }
            }
          },
          "information": {
            "primary": {
              "default": {
                "value": "#007acd"
              },
              "hovered": {
                "value": "#005aa0"
              },
              "focused": {
                "value": "#005aa0"
              },
              "pressed": {
                "value": "#004478"
              }
            },
            "contrast": {
              "default": {
                "value": "#005aa0"
              },
              "hovered": {
                "value": "#004478"
              },
              "focused": {
                "value": "#004478"
              },
              "pressed": {
                "value": "#0b2d4e"
              }
            }
          },
          "discovery": {
            "primary": {
              "default": {
                "value": "#9261b7"
              },
              "hovered": {
                "value": "#744299"
              },
              "focused": {
                "value": "#744299"
              },
              "pressed": {
                "value": "#533669"
              }
            },
            "contrast": {
              "default": {
                "value": "#744299"
              },
              "hovered": {
                "value": "#533669"
              },
              "focused": {
                "value": "#533669"
              },
              "pressed": {
                "value": "#312838"
              }
            }
          },
          "planet": {
            "primary": {
              "default": {
                "value": "#008480"
              },
              "hovered": {
                "value": "#0a6361"
              },
              "focused": {
                "value": "#0a6361"
              },
              "pressed": {
                "value": "#154948"
              }
            },
            "contrast": {
              "default": {
                "value": "#0a6361"
              },
              "hovered": {
                "value": "#154948"
              },
              "focused": {
                "value": "#154948"
              },
              "pressed": {
                "value": "#192f2f"
              }
            }
          }
        },
        "accent": {
          "pink": {
            "primary": {
              "default": {
                "value": "#e01b78"
              },
              "hovered": {
                "value": "#963563"
              },
              "focused": {
                "value": "#963563"
              },
              "pressed": {
                "value": "#5c3749"
              }
            },
            "contrast": {
              "default": {
                "value": "#963563"
              },
              "hovered": {
                "value": "#5c3749"
              },
              "focused": {
                "value": "#5c3749"
              },
              "pressed": {
                "value": "#34282e"
              }
            }
          },
          "red": {
            "primary": {
              "default": {
                "value": "#db3735"
              },
              "hovered": {
                "value": "#aa2424"
              },
              "focused": {
                "value": "#aa2424"
              },
              "pressed": {
                "value": "#7f1d1b"
              }
            },
            "contrast": {
              "default": {
                "value": "#aa2424"
              },
              "hovered": {
                "value": "#7f1d1b"
              },
              "focused": {
                "value": "#7f1d1b"
              },
              "pressed": {
                "value": "#521814"
              }
            }
          },
          "orange": {
            "primary": {
              "default": {
                "value": "#bc5921"
              },
              "hovered": {
                "value": "#8e441a"
              },
              "focused": {
                "value": "#8e441a"
              },
              "pressed": {
                "value": "#693315"
              }
            },
            "contrast": {
              "default": {
                "value": "#8e441a"
              },
              "hovered": {
                "value": "#693315"
              },
              "focused": {
                "value": "#693315"
              },
              "pressed": {
                "value": "#442312"
              }
            }
          },
          "yellow": {
            "primary": {
              "default": {
                "value": "#947023"
              },
              "hovered": {
                "value": "#6f551d"
              },
              "focused": {
                "value": "#6f551d"
              },
              "pressed": {
                "value": "#533f18"
              }
            },
            "contrast": {
              "default": {
                "value": "#6f551d"
              },
              "hovered": {
                "value": "#533f18"
              },
              "focused": {
                "value": "#533f18"
              },
              "pressed": {
                "value": "#362a13"
              }
            }
          },
          "green": {
            "primary": {
              "default": {
                "value": "#008836"
              },
              "hovered": {
                "value": "#006725"
              },
              "focused": {
                "value": "#006725"
              },
              "pressed": {
                "value": "#004d1c"
              }
            },
            "contrast": {
              "default": {
                "value": "#006725"
              },
              "hovered": {
                "value": "#004d1c"
              },
              "focused": {
                "value": "#004d1c"
              },
              "pressed": {
                "value": "#0a3315"
              }
            }
          },
          "grey": {
            "primary": {
              "default": {
                "value": "#60798b"
              },
              "hovered": {
                "value": "#435c6e"
              },
              "focused": {
                "value": "#435c6e"
              },
              "pressed": {
                "value": "#334551"
              }
            },
            "contrast": {
              "default": {
                "value": "#435c6e"
              },
              "hovered": {
                "value": "#334551"
              },
              "focused": {
                "value": "#334551"
              },
              "pressed": {
                "value": "#232d35"
              }
            }
          },
          "blue": {
            "primary": {
              "default": {
                "value": "#007acd"
              },
              "hovered": {
                "value": "#005aa0"
              },
              "focused": {
                "value": "#005aa0"
              },
              "pressed": {
                "value": "#004478"
              }
            },
            "contrast": {
              "default": {
                "value": "#005aa0"
              },
              "hovered": {
                "value": "#004478"
              },
              "focused": {
                "value": "#004478"
              },
              "pressed": {
                "value": "#0b2d4e"
              }
            }
          },
          "ultramarine": {
            "primary": {
              "default": {
                "value": "#6372b2"
              },
              "hovered": {
                "value": "#44549b"
              },
              "focused": {
                "value": "#44549b"
              },
              "pressed": {
                "value": "#2b3d83"
              }
            },
            "contrast": {
              "default": {
                "value": "#44549b"
              },
              "hovered": {
                "value": "#2b3d83"
              },
              "focused": {
                "value": "#2b3d83"
              },
              "pressed": {
                "value": "#182663"
              }
            }
          },
          "purple": {
            "primary": {
              "default": {
                "value": "#9261b7"
              },
              "hovered": {
                "value": "#744299"
              },
              "focused": {
                "value": "#744299"
              },
              "pressed": {
                "value": "#533669"
              }
            },
            "contrast": {
              "default": {
                "value": "#744299"
              },
              "hovered": {
                "value": "#533669"
              },
              "focused": {
                "value": "#533669"
              },
              "pressed": {
                "value": "#312838"
              }
            }
          },
          "teal": {
            "primary": {
              "default": {
                "value": "#008480"
              },
              "hovered": {
                "value": "#0a6361"
              },
              "focused": {
                "value": "#0a6361"
              },
              "pressed": {
                "value": "#154948"
              }
            },
            "contrast": {
              "default": {
                "value": "#0a6361"
              },
              "hovered": {
                "value": "#154948"
              },
              "focused": {
                "value": "#154948"
              },
              "pressed": {
                "value": "#192f2f"
              }
            }
          },
          "lime": {
            "primary": {
              "default": {
                "value": "#6c7d02"
              },
              "hovered": {
                "value": "#525f0a"
              },
              "focused": {
                "value": "#525f0a"
              },
              "pressed": {
                "value": "#3e460e"
              }
            },
            "contrast": {
              "default": {
                "value": "#525f0a"
              },
              "hovered": {
                "value": "#3e460e"
              },
              "focused": {
                "value": "#3e460e"
              },
              "pressed": {
                "value": "#2a2e0e"
              }
            }
          }
        },
        "additional": {
          "signature": {
            "default": {
              "value": "#182663"
            }
          }
        }
      }
    },
    "elevation": {
      "shadow": {
        "2-dp": {
          "value": "0 1px 5px 0 rgba(0, 0, 0, .1), 0 2px 2px 0 rgba(0, 0, 0, .06), 0 3px 1px rgba(0, 0, 0, .06)"
        },
        "4-dp": {
          "value": "0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 5px rgba(0, 0, 0, 0.06), 0 1px 10px rgba(0, 0, 0, 0.06)"
        },
        "6-dp": {
          "value": "0 3px 5px rgba(0, 0, 0, 0.1), 0 6px 10px rgba(0, 0, 0, 0.06), 0 1px 18px rgba(0, 0, 0, 0.06)"
        },
        "8-dp": {
          "value": "0 5px 5px rgba(0, 0, 0, 0.1), 0 8px 10px rgba(0, 0, 0, 0.06), 0 3px 14px rgba(0, 0, 0, 0.06)"
        },
        "16-dp": {
          "value": "0 8px 10px rgba(0, 0, 0, 0.1), 0 16px 24px rgba(0, 0, 0, 0.06), 0 6px 30px rgba(0, 0, 0, 0.06)"
        },
        "24-dp": {
          "value": "0 11px 15px rgba(0, 0, 0, 0.1), 0 24px 38px rgba(0, 0, 0, 0.06), 0 9px 46px rgba(0, 0, 0, 0.06)"
        }
      },
      "z_index": {
        "dropdown": {
          "value": "1000"
        },
        "sticky": {
          "value": "1020"
        },
        "fixed": {
          "value": "1030"
        },
        "modal-backdrop": {
          "value": "1040"
        },
        "modal": {
          "value": "1050"
        },
        "popover": {
          "value": "1060"
        },
        "tooltip": {
          "value": "1070"
        },
        "toast-container": {
          "value": "1080"
        },
        "toast": {
          "value": "1080"
        }
      }
    },
    "font": {
      "family": {
        "sansSerif": {
          "value": "\"Lato\", -apple-system, \"BlinkMacSystemFont\", \"Segoe UI\", \"Roboto\", \"Helvetica Neue\", \"Arial\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\""
        },
        "monospace": {
          "value": "\"SFMono-Regular\", \"Menlo\", \"Monaco\", \"Consolas\", \"Liberation Mono\", \"Courier New\", monospace"
        },
        "default": {
          "value": "\"Lato\", -apple-system, \"BlinkMacSystemFont\", \"Segoe UI\", \"Roboto\", \"Helvetica Neue\", \"Arial\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\""
        }
      },
      "weight": {
        "light": {
          "value": "300"
        },
        "normal": {
          "value": "400"
        },
        "bold": {
          "value": "700"
        },
        "black": {
          "value": "900"
        }
      },
      "line": {
        "height": {
          "default": {
            "value": "1.5"
          },
          "sansSerif": {
            "value": "1.5"
          }
        }
      }
    },
    "motion": {
      "easing": {
        "fastOutSlowIn": {
          "value": "cubic-bezier(.4, 0, .2, 1)"
        },
        "linearOutSlowIn": {
          "value": "cubic-bezier(0, 0, .2, 1)"
        },
        "fastOutLinearIn": {
          "value": "cubic-bezier(.4, 0, 1, 1)"
        }
      }
    },
    "opacity": {
      "disabled": {
        "value": "0.35"
      }
    },
    "size": {
      "border": {
        "width": {
          "value": "0.0625rem"
        },
        "radius": {
          "none": {
            "value": "0"
          },
          "sm": {
            "value": "0"
          },
          "md": {
            "value": "0"
          },
          "lg": {
            "value": "0"
          },
          "pill": {
            "value": "9999px"
          }
        },
        "focus_ring": {
          "width": {
            "value": "0.125rem"
          },
          "offset": {
            "value": "0.125rem"
          }
        }
      },
      "font": {
        "content": {
          "1": {
            "value": "1rem"
          },
          "2": {
            "value": "0.875rem"
          },
          "3": {
            "value": "0.75rem"
          }
        },
        "heading": {
          "1": {
            "value": "3.375rem"
          },
          "2": {
            "value": "2.25rem"
          },
          "3": {
            "value": "1.75rem"
          },
          "4": {
            "value": "1.5rem"
          },
          "5": {
            "value": "1.25rem"
          },
          "6": {
            "value": "1.125rem"
          }
        },
        "display": {
          "1": {
            "value": "4.75rem"
          }
        }
      },
      "icon": {
        "xs": {
          "value": "0.75rem"
        },
        "sm": {
          "value": "1rem"
        },
        "md": {
          "value": "1.5rem"
        },
        "lg": {
          "value": "2rem"
        },
        "xl": {
          "value": "3rem"
        },
        "xxl": {
          "value": "4rem"
        },
        "3xl": {
          "value": "5rem"
        },
        "4xl": {
          "value": "6rem"
        },
        "5xl": {
          "value": "7rem"
        },
        "6xl": {
          "value": "8rem"
        }
      },
      "spacing": {
        "0": {
          "value": "0"
        },
        "2": {
          "value": "0.125rem"
        },
        "4": {
          "value": "0.25rem"
        },
        "8": {
          "value": "0.5rem"
        },
        "12": {
          "value": "0.75rem"
        },
        "16": {
          "value": "1rem"
        },
        "20": {
          "value": "1.25rem"
        },
        "24": {
          "value": "1.5rem"
        },
        "32": {
          "value": "2rem"
        },
        "48": {
          "value": "3rem"
        },
        "64": {
          "value": "4rem"
        },
        "80": {
          "value": "5rem"
        },
        "96": {
          "value": "6rem"
        },
        "112": {
          "value": "7rem"
        }
      }
    }
  },
  "core": {
    "color": {
      "grey": {
        "0": {
          "value": "#ffffff"
        },
        "100": {
          "value": "#f6f8f9"
        },
        "200": {
          "value": "#ebeef1"
        },
        "300": {
          "value": "#ccd4da"
        },
        "400": {
          "value": "#9eadb8"
        },
        "500": {
          "value": "#778c9b"
        },
        "600": {
          "value": "#60798b"
        },
        "700": {
          "value": "#435c6e"
        },
        "800": {
          "value": "#334551"
        },
        "900": {
          "value": "#232d35"
        },
        "1000": {
          "value": "#171d21"
        },
        "alpha": {
          "0": {
            "value": "rgba(0, 0, 0, 0)"
          },
          "100": {
            "value": "rgba(30, 80, 105, 0.04)"
          },
          "200": {
            "value": "rgba(5, 43, 80, 0.08)"
          },
          "300": {
            "value": "rgba(0, 40, 70, 0.2)"
          },
          "400": {
            "value": "rgba(6, 45, 73, 0.39)"
          },
          "500": {
            "value": "rgba(3, 42, 70, 0.54)"
          },
          "inverse": {
            "0": {
              "value": "rgba(0, 0, 0, 0)"
            },
            "100": {
              "value": "rgba(188, 225, 229, 0.03)"
            },
            "200": {
              "value": "rgba(164, 211, 229, 0.07)"
            },
            "300": {
              "value": "rgba(168, 213, 247, 0.17)"
            },
            "400": {
              "value": "rgba(166, 222, 255, 0.31)"
            },
            "500": {
              "value": "rgba(179, 221, 251, 0.45)"
            }
          }
        }
      },
      "pink": {
        "100": {
          "value": "#fff4f9"
        },
        "200": {
          "value": "#ffe9f3"
        },
        "300": {
          "value": "#ffc1df"
        },
        "400": {
          "value": "#ff80bd"
        },
        "500": {
          "value": "#fb3492"
        },
        "600": {
          "value": "#e01b78"
        },
        "700": {
          "value": "#963563"
        },
        "800": {
          "value": "#5c3749"
        },
        "900": {
          "value": "#34282e"
        }
      },
      "red": {
        "100": {
          "value": "#fff7f5"
        },
        "200": {
          "value": "#ffeae5"
        },
        "300": {
          "value": "#ffc5b9"
        },
        "400": {
          "value": "#ff8979"
        },
        "500": {
          "value": "#f34e46"
        },
        "600": {
          "value": "#db3735"
        },
        "700": {
          "value": "#aa2424"
        },
        "800": {
          "value": "#7f1d1b"
        },
        "900": {
          "value": "#521814"
        },
        "alpha": {
          "0": {
            "value": "rgba(0, 0, 0, 0)"
          },
          "100": {
            "value": "rgba(255, 55, 5, 0.04)"
          },
          "200": {
            "value": "rgba(255, 64, 19, 0.11)"
          },
          "300": {
            "value": "rgba(255, 48, 5, 0.28)"
          },
          "400": {
            "value": "rgba(255, 32, 2, 0.53);"
          }
        }
      },
      "orange": {
        "100": {
          "value": "#fff5ed"
        },
        "200": {
          "value": "#ffeada"
        },
        "300": {
          "value": "#ffc89d"
        },
        "400": {
          "value": "#ff8c47"
        },
        "500": {
          "value": "#d96929"
        },
        "600": {
          "value": "#bc5921"
        },
        "700": {
          "value": "#8e441a"
        },
        "800": {
          "value": "#693315"
        },
        "900": {
          "value": "#442312"
        },
        "alpha": {
          "0": {
            "value": "rgba(0, 0, 0, 0)"
          },
          "100": {
            "value": "rgba(255, 130, 30, 0.08)"
          },
          "200": {
            "value": "rgba(255, 115, 8, 0.15)"
          },
          "300": {
            "value": "rgba(255, 114, 4, 0.39)"
          },
          "400": {
            "value": "rgba(255, 97, 3, 0.73);"
          }
        }
      },
      "yellow": {
        "100": {
          "value": "#fff7e0"
        },
        "200": {
          "value": "#ffeeb4"
        },
        "300": {
          "value": "#ffcc59"
        },
        "400": {
          "value": "#d5a334"
        },
        "500": {
          "value": "#ab8327"
        },
        "600": {
          "value": "#947023"
        },
        "700": {
          "value": "#6f551d"
        },
        "800": {
          "value": "#533f18"
        },
        "900": {
          "value": "#362a13"
        }
      },
      "green": {
        "100": {
          "value": "#f3faf4"
        },
        "200": {
          "value": "#e2f3e3"
        },
        "300": {
          "value": "#aedfb3"
        },
        "400": {
          "value": "#63be74"
        },
        "500": {
          "value": "#199d47"
        },
        "600": {
          "value": "#008836"
        },
        "700": {
          "value": "#006725"
        },
        "800": {
          "value": "#004d1c"
        },
        "900": {
          "value": "#0a3315"
        },
        "alpha": {
          "0": {
            "value": "rgba(0, 0, 0, 0)"
          },
          "100": {
            "value": "rgba(35, 155, 35, 0.05)"
          },
          "200": {
            "value": "rgba(13, 155, 22, 0.12)"
          },
          "300": {
            "value": "rgba(2, 155, 18, 0.32)"
          },
          "400": {
            "value": "rgba(3, 150, 31, 0.62);"
          }
        }
      },
      "blue": {
        "100": {
          "value": "#f6f8fd"
        },
        "200": {
          "value": "#e7eefc"
        },
        "300": {
          "value": "#c0d4f9"
        },
        "400": {
          "value": "#7fadef"
        },
        "500": {
          "value": "#3c8cde"
        },
        "600": {
          "value": "#007acd"
        },
        "700": {
          "value": "#005aa0"
        },
        "800": {
          "value": "#004478"
        },
        "900": {
          "value": "#0b2d4e"
        },
        "alpha": {
          "0": {
            "value": "rgba(0, 0, 0, 0)"
          },
          "100": {
            "value": "rgba(30, 80, 205, 0.04)"
          },
          "200": {
            "value": "rgba(15, 85, 225, 0.1)"
          },
          "300": {
            "value": "rgba(3, 83, 231, 0.25)"
          },
          "400": {
            "value": "rgba(4, 94, 224, 0.51);"
          }
        }
      },
      "ultramarine": {
        "100": {
          "value": "#f6f7fb"
        },
        "200": {
          "value": "#eceef7"
        },
        "300": {
          "value": "#ccd1e9"
        },
        "400": {
          "value": "#9fa9d4"
        },
        "500": {
          "value": "#7986bf"
        },
        "600": {
          "value": "#6372b2"
        },
        "700": {
          "value": "#44549b"
        },
        "800": {
          "value": "#2b3d83"
        },
        "900": {
          "value": "#182663"
        }
      },
      "purple": {
        "100": {
          "value": "#faf7fc"
        },
        "200": {
          "value": "#f2ecf7"
        },
        "300": {
          "value": "#deccec"
        },
        "400": {
          "value": "#bf9ed9"
        },
        "500": {
          "value": "#a377c5"
        },
        "600": {
          "value": "#9261b7"
        },
        "700": {
          "value": "#744299"
        },
        "800": {
          "value": "#533669"
        },
        "900": {
          "value": "#312838"
        },
        "alpha": {
          "0": {
            "value": "rgba(0, 0, 0, 0)"
          },
          "100": {
            "value": "rgba(130, 55, 180, 0.04)"
          },
          "200": {
            "value": "rgba(93, 18, 155, 0.08)"
          },
          "300": {
            "value": "rgba(90, 0, 160, 0.20)"
          },
          "400": {
            "value": "rgba(91, 6, 158, 0.39);"
          }
        }
      },
      "teal": {
        "100": {
          "value": "#f1fafa"
        },
        "200": {
          "value": "#daf4f3"
        },
        "300": {
          "value": "#95dfdd"
        },
        "400": {
          "value": "#3dbcb8"
        },
        "500": {
          "value": "#0a9994"
        },
        "600": {
          "value": "#008480"
        },
        "700": {
          "value": "#0a6361"
        },
        "800": {
          "value": "#154948"
        },
        "900": {
          "value": "#192f2f"
        },
        "alpha": {
          "0": {
            "value": "rgba(0, 0, 0, 0)"
          },
          "100": {
            "value": "rgba(22, 172, 172, 0.06)"
          },
          "200": {
            "value": "rgba(8, 182, 175, 0.15)"
          },
          "300": {
            "value": "rgba(3, 179, 174, 0.42)"
          },
          "400": {
            "value": "rgba(3, 168, 163, 0.77);"
          }
        }
      },
      "lime": {
        "100": {
          "value": "#f8fad5"
        },
        "200": {
          "value": "#eef5a8"
        },
        "300": {
          "value": "#c7de43"
        },
        "400": {
          "value": "#9db603"
        },
        "500": {
          "value": "#7d9200"
        },
        "600": {
          "value": "#6c7d02"
        },
        "700": {
          "value": "#525f0a"
        },
        "800": {
          "value": "#3e460e"
        },
        "900": {
          "value": "#2a2e0e"
        }
      },
      "engie": {
        "blue": {
          "value": "#00AAFF"
        },
        "green": {
          "value": "#23d2b5"
        }
      },
      "reference": {
        "brand": {
          "100": {
            "value": "#f6f8fd"
          },
          "200": {
            "value": "#e7eefc"
          },
          "300": {
            "value": "#c0d4f9"
          },
          "400": {
            "value": "#7fadef"
          },
          "500": {
            "value": "#3c8cde"
          },
          "600": {
            "value": "#007acd"
          },
          "700": {
            "value": "#005aa0"
          },
          "800": {
            "value": "#004478"
          },
          "900": {
            "value": "#0b2d4e"
          },
          "alpha": {
            "0": {
              "value": "rgba(0, 0, 0, 0)"
            },
            "100": {
              "value": "rgba(30, 80, 205, 0.04)"
            },
            "200": {
              "value": "rgba(15, 85, 225, 0.1)"
            },
            "300": {
              "value": "rgba(3, 83, 231, 0.25)"
            },
            "400": {
              "value": "rgba(4, 94, 224, 0.51);"
            }
          }
        },
        "neutral": {
          "0": {
            "value": "#ffffff"
          },
          "100": {
            "value": "#f6f8f9"
          },
          "200": {
            "value": "#ebeef1"
          },
          "300": {
            "value": "#ccd4da"
          },
          "400": {
            "value": "#9eadb8"
          },
          "500": {
            "value": "#778c9b"
          },
          "600": {
            "value": "#60798b"
          },
          "700": {
            "value": "#435c6e"
          },
          "800": {
            "value": "#334551"
          },
          "900": {
            "value": "#232d35"
          },
          "1000": {
            "value": "#171d21"
          },
          "alpha": {
            "0": {
              "value": "rgba(0, 0, 0, 0)"
            },
            "100": {
              "value": "rgba(30, 80, 105, 0.04)"
            },
            "200": {
              "value": "rgba(5, 43, 80, 0.08)"
            },
            "300": {
              "value": "rgba(0, 40, 70, 0.2)"
            },
            "400": {
              "value": "rgba(6, 45, 73, 0.39)"
            },
            "500": {
              "value": "rgba(3, 42, 70, 0.54)"
            },
            "inverse": {
              "0": {
                "value": "rgba(0, 0, 0, 0)"
              },
              "100": {
                "value": "rgba(188, 225, 229, 0.03)"
              },
              "200": {
                "value": "rgba(164, 211, 229, 0.07)"
              },
              "300": {
                "value": "rgba(168, 213, 247, 0.17)"
              },
              "400": {
                "value": "rgba(166, 222, 255, 0.31)"
              },
              "500": {
                "value": "rgba(179, 221, 251, 0.45)"
              }
            }
          }
        },
        "status": {
          "danger": {
            "100": {
              "value": "#fff7f5"
            },
            "200": {
              "value": "#ffeae5"
            },
            "300": {
              "value": "#ffc5b9"
            },
            "400": {
              "value": "#ff8979"
            },
            "500": {
              "value": "#f34e46"
            },
            "600": {
              "value": "#db3735"
            },
            "700": {
              "value": "#aa2424"
            },
            "800": {
              "value": "#7f1d1b"
            },
            "900": {
              "value": "#521814"
            },
            "alpha": {
              "0": {
                "value": "rgba(0, 0, 0, 0)"
              },
              "100": {
                "value": "rgba(255, 55, 5, 0.04)"
              },
              "200": {
                "value": "rgba(255, 64, 19, 0.11)"
              },
              "300": {
                "value": "rgba(255, 48, 5, 0.28)"
              },
              "400": {
                "value": "rgba(255, 32, 2, 0.53);"
              }
            }
          },
          "success": {
            "100": {
              "value": "#f3faf4"
            },
            "200": {
              "value": "#e2f3e3"
            },
            "300": {
              "value": "#aedfb3"
            },
            "400": {
              "value": "#63be74"
            },
            "500": {
              "value": "#199d47"
            },
            "600": {
              "value": "#008836"
            },
            "700": {
              "value": "#006725"
            },
            "800": {
              "value": "#004d1c"
            },
            "900": {
              "value": "#0a3315"
            },
            "alpha": {
              "0": {
                "value": "rgba(0, 0, 0, 0)"
              },
              "100": {
                "value": "rgba(35, 155, 35, 0.05)"
              },
              "200": {
                "value": "rgba(13, 155, 22, 0.12)"
              },
              "300": {
                "value": "rgba(2, 155, 18, 0.32)"
              },
              "400": {
                "value": "rgba(3, 150, 31, 0.62);"
              }
            }
          },
          "warning": {
            "100": {
              "value": "#fff5ed"
            },
            "200": {
              "value": "#ffeada"
            },
            "300": {
              "value": "#ffc89d"
            },
            "400": {
              "value": "#ff8c47"
            },
            "500": {
              "value": "#d96929"
            },
            "600": {
              "value": "#bc5921"
            },
            "700": {
              "value": "#8e441a"
            },
            "800": {
              "value": "#693315"
            },
            "900": {
              "value": "#442312"
            },
            "alpha": {
              "0": {
                "value": "rgba(0, 0, 0, 0)"
              },
              "100": {
                "value": "rgba(255, 130, 30, 0.08)"
              },
              "200": {
                "value": "rgba(255, 115, 8, 0.15)"
              },
              "300": {
                "value": "rgba(255, 114, 4, 0.39)"
              },
              "400": {
                "value": "rgba(255, 97, 3, 0.73);"
              }
            }
          },
          "discovery": {
            "100": {
              "value": "#faf7fc"
            },
            "200": {
              "value": "#f2ecf7"
            },
            "300": {
              "value": "#deccec"
            },
            "400": {
              "value": "#bf9ed9"
            },
            "500": {
              "value": "#a377c5"
            },
            "600": {
              "value": "#9261b7"
            },
            "700": {
              "value": "#744299"
            },
            "800": {
              "value": "#533669"
            },
            "900": {
              "value": "#312838"
            },
            "alpha": {
              "0": {
                "value": "rgba(0, 0, 0, 0)"
              },
              "100": {
                "value": "rgba(130, 55, 180, 0.04)"
              },
              "200": {
                "value": "rgba(93, 18, 155, 0.08)"
              },
              "300": {
                "value": "rgba(90, 0, 160, 0.20)"
              },
              "400": {
                "value": "rgba(91, 6, 158, 0.39);"
              }
            }
          },
          "information": {
            "100": {
              "value": "#f6f8fd"
            },
            "200": {
              "value": "#e7eefc"
            },
            "300": {
              "value": "#c0d4f9"
            },
            "400": {
              "value": "#7fadef"
            },
            "500": {
              "value": "#3c8cde"
            },
            "600": {
              "value": "#007acd"
            },
            "700": {
              "value": "#005aa0"
            },
            "800": {
              "value": "#004478"
            },
            "900": {
              "value": "#0b2d4e"
            },
            "alpha": {
              "0": {
                "value": "rgba(0, 0, 0, 0)"
              },
              "100": {
                "value": "rgba(30, 80, 205, 0.04)"
              },
              "200": {
                "value": "rgba(15, 85, 225, 0.1)"
              },
              "300": {
                "value": "rgba(3, 83, 231, 0.25)"
              },
              "400": {
                "value": "rgba(4, 94, 224, 0.51);"
              }
            }
          },
          "planet": {
            "100": {
              "value": "#f1fafa"
            },
            "200": {
              "value": "#daf4f3"
            },
            "300": {
              "value": "#95dfdd"
            },
            "400": {
              "value": "#3dbcb8"
            },
            "500": {
              "value": "#0a9994"
            },
            "600": {
              "value": "#008480"
            },
            "700": {
              "value": "#0a6361"
            },
            "800": {
              "value": "#154948"
            },
            "900": {
              "value": "#192f2f"
            },
            "alpha": {
              "0": {
                "value": "rgba(0, 0, 0, 0)"
              },
              "100": {
                "value": "rgba(22, 172, 172, 0.06)"
              },
              "200": {
                "value": "rgba(8, 182, 175, 0.15)"
              },
              "300": {
                "value": "rgba(3, 179, 174, 0.42)"
              },
              "400": {
                "value": "rgba(3, 168, 163, 0.77);"
              }
            }
          }
        }
      }
    },
    "elevation": {
      "z_index": {
        "0": {
          "value": "0"
        },
        "1": {
          "value": "1000"
        },
        "10": {
          "value": "1010"
        },
        "20": {
          "value": "1020"
        },
        "30": {
          "value": "1030"
        },
        "40": {
          "value": "1040"
        },
        "50": {
          "value": "1050"
        },
        "60": {
          "value": "1060"
        },
        "70": {
          "value": "1070"
        },
        "80": {
          "value": "1080"
        },
        "90": {
          "value": "1090"
        },
        "100": {
          "value": "1100"
        },
        "110": {
          "value": "1110"
        },
        "120": {
          "value": "1120"
        },
        "130": {
          "value": "1130"
        },
        "140": {
          "value": "1140"
        },
        "150": {
          "value": "1150"
        },
        "160": {
          "value": "1160"
        },
        "170": {
          "value": "1170"
        },
        "180": {
          "value": "1180"
        },
        "190": {
          "value": "1190"
        },
        "200": {
          "value": "1200"
        },
        "210": {
          "value": "1210"
        },
        "220": {
          "value": "1220"
        },
        "230": {
          "value": "1230"
        },
        "240": {
          "value": "1240"
        },
        "250": {
          "value": "1250"
        },
        "260": {
          "value": "1260"
        },
        "270": {
          "value": "1270"
        },
        "280": {
          "value": "1280"
        },
        "290": {
          "value": "1290"
        },
        "300": {
          "value": "1300"
        },
        "310": {
          "value": "1310"
        },
        "320": {
          "value": "1320"
        },
        "330": {
          "value": "1330"
        },
        "340": {
          "value": "1340"
        },
        "350": {
          "value": "1350"
        },
        "360": {
          "value": "1360"
        },
        "370": {
          "value": "1370"
        },
        "380": {
          "value": "1380"
        },
        "390": {
          "value": "1390"
        },
        "400": {
          "value": "1400"
        },
        "410": {
          "value": "1410"
        },
        "420": {
          "value": "1420"
        },
        "430": {
          "value": "1430"
        },
        "440": {
          "value": "1440"
        },
        "450": {
          "value": "1450"
        },
        "460": {
          "value": "1460"
        },
        "470": {
          "value": "1470"
        },
        "480": {
          "value": "1480"
        },
        "490": {
          "value": "1490"
        },
        "500": {
          "value": "1500"
        }
      }
    },
    "opacity": {
      "50": {
        "value": "0.05"
      },
      "100": {
        "value": "0.10"
      },
      "150": {
        "value": "0.15"
      },
      "200": {
        "value": "0.20"
      },
      "250": {
        "value": "0.25"
      },
      "300": {
        "value": "0.30"
      },
      "350": {
        "value": "0.35"
      },
      "400": {
        "value": "0.40"
      },
      "450": {
        "value": "0.45"
      },
      "500": {
        "value": "0.50"
      },
      "550": {
        "value": "0.55"
      },
      "600": {
        "value": "0.60"
      },
      "650": {
        "value": "0.65"
      },
      "700": {
        "value": "0.70"
      },
      "750": {
        "value": "0.75"
      },
      "800": {
        "value": "0.80"
      },
      "850": {
        "value": "0.85"
      },
      "900": {
        "value": "0.90"
      },
      "950": {
        "value": "0.95"
      },
      "1000": {
        "value": "1.00"
      }
    },
    "size": {
      "0": {
        "value": "0"
      },
      "1": {
        "value": "0.0625rem"
      },
      "2": {
        "value": "0.125rem"
      },
      "4": {
        "value": "0.25rem"
      },
      "6": {
        "value": "0.375rem"
      },
      "8": {
        "value": "0.5rem"
      },
      "10": {
        "value": "0.625rem"
      },
      "12": {
        "value": "0.75rem"
      },
      "14": {
        "value": "0.875rem"
      },
      "16": {
        "value": "1rem"
      },
      "18": {
        "value": "1.125rem"
      },
      "20": {
        "value": "1.25rem"
      },
      "22": {
        "value": "1.375rem"
      },
      "24": {
        "value": "1.5rem"
      },
      "28": {
        "value": "1.75rem"
      },
      "30": {
        "value": "1.875rem"
      },
      "32": {
        "value": "2rem"
      },
      "34": {
        "value": "2.125rem"
      },
      "36": {
        "value": "2.25rem"
      },
      "38": {
        "value": "2.375rem"
      },
      "40": {
        "value": "2.5rem"
      },
      "42": {
        "value": "2.625rem"
      },
      "44": {
        "value": "2.75rem"
      },
      "46": {
        "value": "2.875rem"
      },
      "48": {
        "value": "3rem"
      },
      "50": {
        "value": "3.125rem"
      },
      "52": {
        "value": "3.25rem"
      },
      "54": {
        "value": "3.375rem"
      },
      "56": {
        "value": "3.5rem"
      },
      "58": {
        "value": "3.625rem"
      },
      "64": {
        "value": "4rem"
      },
      "76": {
        "value": "4.75rem"
      },
      "80": {
        "value": "5rem"
      },
      "96": {
        "value": "6rem"
      },
      "112": {
        "value": "7rem"
      },
      "116": {
        "value": "7.25rem"
      },
      "128": {
        "value": "8rem"
      },
      "9999": {
        "value": "9999px"
      }
    }
  },
  "component": {
    "avatar": {
      "size": {
        "sm": {
          "value": "2rem"
        },
        "base": {
          "value": "3rem"
        },
        "lg": {
          "value": "4rem"
        },
        "xl": {
          "value": "6rem"
        }
      }
    },
    "backdrop": {
      "color": {
        "background": {
          "value": "rgba(3, 42, 70, 0.54)"
        }
      }
    },
    "fab": {
      "size": {
        "sm": {
          "value": "3rem"
        },
        "base": {
          "value": "4rem"
        }
      }
    },
    "skeleton": {
      "color": {
        "background": {
          "value": "linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(23, 29, 33, 0.05) 50%, rgba(0, 0, 0, 0) 100%)"
        }
      }
    }
  }
};