/**
 * Converts any string to camel case
 * @param str
 * @returns {string}
 */
const camalize = str => {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};
const transformObjectKeys = (obj, transformerFct) => {
  const tranformFct = transformerFct ?? (val => val);
  if (Array.isArray(obj)) {
    return obj.map(value => transformObjectKeys(value));
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((accumulator, key) => ({
      ...accumulator,
      [tranformFct(key)]: transformObjectKeys(obj[key])
    }), {});
  }
  return obj;
};
const mapObjectValuesToArrayValues = originalObject => {
  return Object.fromEntries(Object.entries(originalObject).map(([key, value]) => [key, [value]]));
};
const flattenObject = (obj, parentKey = '', separator = '-') => {
  return Object.keys(obj).reduce((acc, key) => {
    const newKey = parentKey ? `${parentKey}${separator}${key}` : key;
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(acc, flattenObject(obj[key], newKey, separator));
    } else {
      acc[newKey] = obj[key];
    }
    return acc;
  }, {});
};
module.exports = {
  camalize,
  transformObjectKeys,
  flattenObject,
  mapObjectValuesToArrayValues
};